import React from 'react';
import {navigate} from 'gatsby';
import {compose} from 'recompose';
import {ReactTypeformEmbed} from 'react-typeform-embed';
import * as ROUTES from '../constants/routes';

import {withAuthorization, withEmailVerification} from '../components/Session';

// eslint-disable-next-line react/prefer-stateless-function
class SurveyWidget extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const surveyURL = `https://smrutiparida825144.typeform.com/to/SqCI9u`;
		const onSurveyComplete = () => {
			this.props.firebase
				.user(this.props.firebase.auth.currentUser.uid)
				.update({
					didTakeSurvey: true,
				})
				.then(navigate(ROUTES.LANDING));
		};
		return <ReactTypeformEmbed url={surveyURL} onSubmit={onSurveyComplete} />;
	}
}

const condition = (authUser) => !!authUser;

const Survey = compose(
	withEmailVerification,
	withAuthorization(condition),
)(SurveyWidget);

export default (props) => <Survey {...props} />;
